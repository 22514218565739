import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

const otherAnswerRegex = new RegExp('^(other|otro|otra)$', 'i')
const noneAnswerRegex = new RegExp('^(none|ninguno|ninguna)$', 'i')

class Checkboxes extends React.Component {
  state = {
    value: this.props.question.value || [],
    other: "",
    none: false
  }

  ///////////////////////////////////////////////
  // Event Handlers
  ///////////////////////////////////////////////

  // normal checkboxes
  handleChange = (event) => {
    if(event.target.checked) {
      if(this.state.none){
        // if "none" is checked, de-select it automatically
        this.setState({ none: false, value: [event.target.value] })
      } else {
        this.setState({ value: [...this.state.value, event.target.value] })
      }
    } else {
      // remove the target value and any blank "other" values hanging around
      this.setState({ value: this.state.value.filter(checkbox => checkbox !== event.target.value && checkbox !== '') })
    }
  }

  // "other" checkbox's text input
  handleOther = (event) => {
    this.setState({ other: event.target.value })
  }

  // "none" checkbox
  handleNone = (event) => {
    if(event.target.checked) {
      this.setState({ none: true, other: '', value: [event.target.value] })
    } else {
      this.setState({ none: false, value: this.state.value.filter(checkbox => checkbox !== event.target.value) })
    }
  }

  handleSubmit = (event) => {
    // collect answers and potential "other" value
    let answers = this.state.value
    answers.push(this.state.other)

    // remove duplicate values and submit
    let uniqueAnswers = [...new Set(answers)]
    this.props.onSubmit(this.props.question.id, uniqueAnswers)
  }

  ///////////////////////////////////////////////
  // DOM
  ///////////////////////////////////////////////

  renderAnswers = (question) => {
    return(
      question.answers.map((answer) => {
        if(otherAnswerRegex.test(answer.prompt)) {
          return(
            <Form.Check
              className="form-check fs-5 mb-3"
              tabIndex="-1"
              key={answer.id}
              type="checkbox"
              name={question.id}
              id={answer.id}
              label={
                <Form.Control
                  type="text"
                  placeholder={answer.prompt}
                  value={this.state.other}
                  onChange={this.handleOther}
                  onFocus={() => { this.setState({ value: [...this.state.value, answer.id] }) }}
                  size="lg"
                />
              }
              value={answer.id}
              checked={this.state.value.includes(answer.id)}
              onChange={this.handleChange}
            />
          )
        } else if(noneAnswerRegex.test(answer.prompt)) {
          return(
            <Form.Check
              className="form-check fs-5 mb-4"
              key={answer.id}
              type="checkbox"
              name={question.id}
              id={answer.id}
              label={answer.prompt}
              value={answer.id}
              checked={this.state.value.includes(answer.id)}
              onChange={this.handleNone}
            />
          )
        } else {
          return(
            <Form.Check
              className="form-check fs-5 mb-4"
              key={answer.id}
              type="checkbox"
              name={question.id}
              id={answer.id}
              label={answer.prompt}
              value={answer.id}
              checked={this.state.value.includes(answer.id)}
              onChange={this.handleChange}
            />
          )
        }
      })
    )
  }

  ///////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////

  componentDidMount() {
    // initialize state w/ "other" value (always last in the array)
    let answers = this.props.question.value || []
    if(answers.length > 0){ this.setState({ other: answers.slice(-1)[0] }) }

    // find the answer id for "none" (if it exists) and initialize state appropriately
    let noneAnswer = this.props.question.answers.find((answer) => { return noneAnswerRegex.test(answer.prompt) })
    if(noneAnswer){ if(answers.includes(noneAnswer.id)){ this.setState({ none: true }) } }
  }

  render() {
    return(
      <React.Fragment>
        {this.renderAnswers(this.props.question)}
        <Button
          variant="primary"
          size="lg"
          className="w-100 mb-3"
          onClick={this.handleSubmit}
          disabled={this.state.value.length == 0}
        >
          {this.props.question.cta || this.props.next_button || 'Next'}
        </Button>
      </React.Fragment>
    )
  }
}

Checkboxes.propTypes = {
  question: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default Checkboxes
