import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'

class RadioButtons extends React.Component {
  render() {
    const question = this.props.question

    return(
      question.answers.map((answer) => {
        return(
          <Form.Check
            className="form-check fs-5 mb-4"
            tabIndex="-1"
            key={answer.id}
            type="radio"
            name={question.id}
            id={answer.id}
            label={answer.prompt}
            defaultChecked={answer.id == question.value}
            onClick={() => this.props.onSubmit(question.id, answer.id)}
          />
        )
      })
    )
  }
}

RadioButtons.propTypes = {
  question: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default RadioButtons
