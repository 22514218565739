import React from 'react'

// This custom checkbox component can be used by adding a hidden checkbox on a form with a specific
// id. The component will work in such a way that it will toggle the checkbox as it's toggled. Additionally,
// you can pass a string called 'description' to have certain text like a consent appear next to it.
// If you want it to start checked, pass a value for that prop as 'true'

class CheckBox extends React.Component {
  state = {
    checkBoxId: this.props.checkbox_id,
    description: this.props.description,
    classList: this.props.class_list,
    checked: this.props.checked,
    immutable: this.props.immutable,
    onClick: this.props.on_click,
    onUpdate: this.props.on_update
  }

  toggleCheckBox = () => {
    if (this.state.immutable) return;
    
    let checkBox = $(`#${this.state.checkBoxId}`)[0];
    checkBox.checked = !checkBox.checked;
    this.setState({ checked: checkBox.checked });

    if (this.state.onClick) {
      this.state.onClick();
    }
  }

  componentDidUpdate() {
    if (this.state.onUpdate) this.state.onUpdate();
  }

  componentDidMount() {
    let checkBox = $(`#${this.state.checkBoxId}`)[0];
    checkBox.checked = !!this.state.checked;
    this.setState({ checked: checkBox.checked });

    // If setting onClick from Rails view, you have to pass it as a string with all the code that will be included
    // in the onClick function, but without actually declaring it as a function. For example....
    // from the view, instead of setting the prop as '() => { console.log(this); }'
    // pass it as 'console.log(this);' That will convert it into a function properly.
    if (typeof this.state.onClick === 'string') {
      let newFunction = new Function(this.state.onClick);
      this.setState({ onClick: newFunction })
    }
  }


  render() {
    let checkBoxIcon = (this.state.checked ? (<i className='bi bi-check-square-fill'></i>) : (<i className='bi bi-square'></i>))
    let classList =  `d-flex custom-checkbox ${this.state.classList}`
    return (
      <div className={classList} role='button' onClick={this.toggleCheckBox}>
        <div className='me-3'>{checkBoxIcon}</div>
        <div className='user-select-none description'>{this.state.description}</div>
      </div>
    )
  }
}

export default CheckBox
