import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

class Text extends React.Component {
  state = {
    value: this.props.question.value || ''
  }

  ///////////////////////////////////////////////
  // Event Handlers
  ///////////////////////////////////////////////

  handleChange = (event) => {
    this.setState({ value: event.target.value })
  }

  handleSubmit = (event) => {
    this.props.onSubmit(this.props.question.id, this.state.value)
  }

  ///////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////

  render() {
    return (
      <React.Fragment>
        <Form.Control
          type="text"
          tabIndex="-1"
          name={this.props.question.id}
          id={this.props.question.answer.id}
          placeholder={this.props.question.answer.placeholder}
          className="mb-4"
          value={this.state.value}
          onChange={this.handleChange}
          size="lg"
        />
        <Button
          variant="primary"
          size="lg"
          className="w-100 mb-3"
          onClick={this.handleSubmit}
          disabled={this.state.value.length == 0}
        >
          {this.props.question.cta || this.props.next_button || 'Next'}
        </Button>
      </React.Fragment>
    );
  }
}

Text.propTypes = {
  question: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default Text
