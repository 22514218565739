import React from 'react'
import HelloSign from 'hellosign-embedded'
import axios from 'axios'

const client = new HelloSign({ clientId: process.env.HELLOSIGN_CLIENT_ID })

class Consent extends React.Component {
  state = {
    loaded: false,
    signUrl: ''
  }

  ///////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////

  componentDidMount() {
    axios.get('/intake/consent/signature-request').then(response => {
      this.setState({ loaded: true, signUrl: response.data.sign_url })
    }).catch(function (error) {
      console.error(error);
      window.location = '/intake'
    })
  }

  render() {
    if(this.state.loaded) {
      client.open(this.state.signUrl, {
        testMode: true,
        debug: true,
        allowCancel: false,
        hideHeader: true,
        redirectTo: '/intake',
        container: document.getElementById('hellosign-mount'),
        whiteLabeling: {
          page_background_color: '#FFFFFF',
          header_background_color: '#F6F8FC',
          primary_button_color: '#3E3E9D',
          text_color2: '#3E3E9D'
        }
      })

      client.on('sign', (data) => {
        console.log(`The signature request was signed!`, data);
        axios.post('/intake/consent/signature-request', {
          signature_id: data.signatureId
        }).then(response => {
          console.log("success!", response);
          window.location = "/intake"
        })
      })

      client.on('ready', (data) => {
        window.Spinner.hide()
      })
    }
    return (
      <React.Fragment>
      </React.Fragment>
    )
  }
}

export default Consent
