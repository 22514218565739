// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"
import "channels"
import Cleave from 'cleave.js'
import "cleave.js/dist/addons/cleave-phone.us"

// Start framework libraries
Rails.start()
Turbolinks.start()
ActiveStorage.start()

// Configure Sentry
Sentry.init({
  dsn: "https://682fe8b1fa2249f3b9362db2196209af@o999322.ingest.sentry.io/5958219",
  release: process.env.APP_RELEASE,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: process.env.RAILS_ENV == "production" ? 0.1 : 0
});

// Use jQuery and get over yourself
var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// Allow javascript links
$(document).on('click', '[data-link]', function(event){
  event.preventDefault()
  window.location = $(this).data('link')
});

// Force refreshing of CSRF tokens when turbolinks loads | https://stackoverflow.com/a/63938925
$(document).on('turbolinks:load', function(){ Rails.refreshCSRFTokens(); });

// Format any telephone fields
$(document).on('turbolinks:load', function(){
  $('input[type="tel"]').each(function(){
    var cleave = new Cleave(`#${$(this).attr('id')}`, {
      phone: true,
      phoneRegionCode: 'us'
    })
  });
});
