import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

class Select extends React.Component {
  state = {
    value: this.props.question.value || ''
  }

  ///////////////////////////////////////////////
  // Event Handlers
  ///////////////////////////////////////////////

  handleChange = (event) => {
    this.setState({ value: event.target.value })
  }

  handleSubmit = (event) => {
    this.props.onSubmit(this.props.question.id, this.state.value)
  }

  ///////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////

  render() {
    let arrayRangeOptions = (start, stop, step, text, firstOptions) => {
      let arr = Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
      )
      let newArr = arr.map((num) => {
        return [`${num} ${text}`.trim(), `${num} ${text}`.trim()];
      });
      if (!!firstOptions) { firstOptions.forEach((option) => { newArr.unshift(option) }) }
      return newArr;
    }

    let ageRangeOptions = () => {
      console.log(this.props.locale)
      // FOR SPANISH TRANSLATION
      let months = "months"
      let years = "years"
      let nullOption = "— select age —"
      let naOption = "N/A"
      if (this.props.locale == "es") {
        months = "meses"
        years = "años"
        nullOption = "— seleccione la edad —"
        naOption = "N/D"
      }

      let arrMonths = arrayRangeOptions(3, 36, 1, months, null)
      let arrYears = arrayRangeOptions(4, 17, 1, years, null)
      let arr = arrMonths.concat(arrYears)
      arr.unshift(['N/A', naOption])
      arr.unshift(['null', nullOption])
      return arr;
    }

    let optionsArray = eval(this.props.question.answer.options)
    let options = optionsArray.map((ele, idx) => {
      let isDisabled = (idx == 0);
      let isSelected = (idx == 0);
      return <option selected={isSelected} key={idx} disabled={isDisabled} value={ele[0]}>{ele[1]}</option>
    })
    return (
      <React.Fragment>
        <Form.Control
          as="select"
          className="form-select form-select-lg mb-3"
          onChange={this.handleChange}
        >{options}</Form.Control>
        <Button
          variant="primary"
          size="lg"
          className="w-100 mb-3"
          onClick={this.handleSubmit}
          disabled={!this.state.value}
        >
          {this.props.question.cta || this.props.next_button || 'Next'}
        </Button>
      </React.Fragment>
    );
  }
}

Text.propTypes = {
  question: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default Select
