import axios from 'axios';
import React from 'react'
import CheckBox from './CheckBox'

class SummaryGenerator extends React.Component {
  state = {
    evaluationDate: this.props.evaluation_date,
    updateUrl: this.props.update_url,
    generateUrl: this.props.generate_url,
    redirectUrl: this.props.redirect_url,
    shouldRedirect: false
  }

  formatDiagnosis = () => {
    let inputChecked = (input) => {
      return (input.checked)
    };
    let filterAndMapNodeList = (nodeList, callback) => {
      let nodes = Array.from(nodeList).filter(callback);
      return nodes.map((node) => { return node.defaultValue; })
    }

    let firstInputGroup = filterAndMapNodeList($('input.input-group-1'), inputChecked);
    let secondInputGroup = filterAndMapNodeList($('input.input-group-2'), inputChecked);
    let thirdInputGroup = filterAndMapNodeList($('input.input-group-3'), inputChecked);
    
    let firstGroup = firstInputGroup.join(', ')
    let secondGroup = secondInputGroup.join(', ')
    let thirdGroup = thirdInputGroup.join(', ')

    let otherCheckBox = $('#placeholder-checkbox-6')[0]
    let otherTextInput = $('input.other-text')[0]
    let fourthGroup = (otherCheckBox.checked ? otherTextInput.value : null);
    
    let allGroups = [firstGroup, secondGroup, thirdGroup, fourthGroup].filter((group) => { return group; });

    return allGroups.join("; ")
  }

  formatDate = () => {
    let input = $('input.evaluation-date')[0]
    if (input) return input.value;
  }

  formatGenerateUrl = () => {
    let formattedDiagnosis = encodeURIComponent(this.formatDiagnosis());
    let formattedDate = this.formatDate();

    let params = { 
      diagnosis: formattedDiagnosis,
      evaluation_date: formattedDate
    }

    let url = this.state.generateUrl.split('?')[0];
    if (!params.diagnosis) return url;

    return (url + `?diagnosis=${params.diagnosis}&evaluation_date=${params.evaluation_date}`);
  }

  ///////////////////////////////////////////////
  // Event Handlers
  ///////////////////////////////////////////////

  onGroup1Click = () => {
    $('.checkbox-subgroup-1')[0].classList.toggle("d-none")
    // Force child to re-render
    this.setState({});
  }

  onGroup2Click = () => {
    $('.checkbox-subgroup-2')[0].classList.toggle("d-none")
    // Force child to re-render
    this.setState({});
  }

  onParentCheckBoxClick = (className, parent_num) => {
    return () => {
      if (!$(`#placeholder-checkbox-${parent_num}`)[0].checked && $(`#placeholder-${className}`)[0].checked) {
        $(`.${className}`)[0].click();
      }
    }
  }

  onOtherCheckBoxClick = () => {
    let otherCheckBox = $('#placeholder-checkbox-6')[0];
    let otherTextInput = $('input.other-text')[0];

    otherTextInput.classList.toggle("d-none");
    if (!otherCheckBox.checked) otherTextInput.value = "";
  }

  onPreviewClick = () => {
    let url = this.formatGenerateUrl();
    console.log(url);
    window.open(url, '_blank');
  }

  ///////////////////////////////////////////////
  // Axios
  ///////////////////////////////////////////////

  onClick = () => {
    let formattedDiagnosis = this.formatDiagnosis();
    let formattedDate = this.formatDate();

    let params = { 
      diagnosis: formattedDiagnosis,
      evaluation_date: formattedDate
    }

    let allowRedirect = () => {
      this.setState({ shouldRedirect: true });
    }

    axios.patch(this.state.updateUrl, params).then(function (result) {
      console.log(result);
      allowRedirect();
    }).catch(function (err) {
      console.log(err);
    });
  }
  
  render() {
    // Redirecting after POST request
    if (this.state.shouldRedirect) return (<meta httpEquiv="refresh" content={`0; URL=${this.state.redirectUrl}`} />)

    return (
      <div className="summary-form d-flex flex-column">
        <div>
          <input type="check_box" className="d-none input-group-1" id="placeholder-checkbox-1" value="F84 Autism Spectrum Disorder" readOnly/>
          <input type="check_box" className="d-none input-group-1" id="placeholder-checkbox-1a" value="with accompanying language impairment" readOnly/>
          <input type="check_box" className="d-none input-group-1" id="placeholder-checkbox-1b" value="with intellectual delays" readOnly/>
          <input type="check_box" className="d-none input-group-1" id="placeholder-checkbox-1c" value="monitor for intellectual delays" readOnly/>
          <input type="check_box" className="d-none input-group-2" id="placeholder-checkbox-2" value="F88 Unspecified Neurodevelopmental Disorder" readOnly/>
          <input type="check_box" className="d-none input-group-2" id="placeholder-checkbox-2a" value="subclinical/symptoms of ASD" readOnly/>
          <input type="check_box" className="d-none input-group-2" id="placeholder-checkbox-2b" value="subclinical/symptoms of ADHD" readOnly/>
          <input type="check_box" className="d-none input-group-2" id="placeholder-checkbox-2c" value="subclinical/symptoms of Sensory Processing Disorder" readOnly/>
          <input type="check_box" className="d-none input-group-3" id="placeholder-checkbox-3" value="F90.0 ADHD-Inattentive Type" readOnly/>
          <input type="check_box" className="d-none input-group-3" id="placeholder-checkbox-4" value="F90.1 ADHD-Hyperactive Type" readOnly/>
          <input type="check_box" className="d-none input-group-3" id="placeholder-checkbox-5" value="F90.2 ADHD-Combined Type" readOnly/>
          <input type="check_box" className="d-none input-group-4" id="placeholder-checkbox-6" value="Other" readOnly/>
        </div>

        <div className="diagnosis-input mb-3">
          <label className="mb-2"><strong>Diagnosis</strong></label>
          <div className='input-group-1'>
            <CheckBox checkbox_id="placeholder-checkbox-1"
              description="F84 Autism Spectrum Disorder"
              class_list="text-primary w-100 checkbox-1" 
              on_click={this.onGroup1Click}/>
            <div className='checkbox-subgroup-1 ps-4 d-none'>
              <CheckBox checkbox_id="placeholder-checkbox-1a"
                description="with accompanying language impairment"
                class_list="text-primary w-100 checkbox-1a"
                on_update={this.onParentCheckBoxClick('checkbox-1a', 1)} />
              <CheckBox checkbox_id="placeholder-checkbox-1b"
                description="with intellectual delays"
                class_list="text-primary w-100 checkbox-1b" 
                on_update={this.onParentCheckBoxClick('checkbox-1b', 1)} />
              <CheckBox checkbox_id="placeholder-checkbox-1c"
                description="monitor for intellectual delays"
                class_list="text-primary w-100 checkbox-1c mb-2" 
                on_update={this.onParentCheckBoxClick('checkbox-1c', 1)} />
            </div>
          </div>

          <div className='input-group-2'>
            <CheckBox checkbox_id="placeholder-checkbox-2"
              description="F89 Unspecified Neurodevelopmental Disorder"
              class_list="text-green w-100" 
              on_click={this.onGroup2Click} />
            <div className='checkbox-subgroup-2 ps-4 d-none'>
              <CheckBox checkbox_id="placeholder-checkbox-2a"
                description="subclinical/symptoms of ASD"
                class_list="text-green w-100 checkbox-2a" 
                on_update={this.onParentCheckBoxClick('checkbox-2a', 2)} />
              <CheckBox checkbox_id="placeholder-checkbox-2b"
                description="subclinical/symptoms of ADHD"
                class_list="text-green w-100 checkbox-2b" 
                on_update={this.onParentCheckBoxClick('checkbox-2b', 2)} />
              <CheckBox checkbox_id="placeholder-checkbox-2c"
                description="subclinical/symptoms of Sensory Processing Disorder"
                class_list="text-green w-100 checkbox-2c mb-2" 
                on_update={this.onParentCheckBoxClick('checkbox-2c', 2)} />
            </div>
          </div>

          <div className='input-group-3'>
            <CheckBox checkbox_id="placeholder-checkbox-3"
              description="F90.0 ADHD-Inattentive Type"
              class_list="text-blue w-100 checkbox-3" />
            <CheckBox checkbox_id="placeholder-checkbox-4"
              description="F90.1 ADHD-Hyperactive Type"
              class_list="text-blue w-100 checkbox-4" />
            <CheckBox checkbox_id="placeholder-checkbox-5"
              description="F90.2 ADHD-Combined Type"
              class_list="text-blue w-100 checkbox-5" />
          </div>
          
          <div className='input-group-4'>
            <CheckBox checkbox_id="placeholder-checkbox-6"
              description="Other diagnosis"
              class_list="text-dark w-100 checkbox-3" 
              on_click={this.onOtherCheckBoxClick} />
            <input type='text' placeholder='Write other text here...' className='mt-2 ms-4 form-control other-text d-none' />
          </div>
        </div>


        <div className='date-input'>
          <label htmlFor="evaluation_date" className="mb-2"><strong>Evaluation Date</strong></label>
          <input className="form-control mb-3 evaluation-date" type="date" defaultValue={this.state.evaluationDate} id="evaluation_date" />
        </div>

        <div className='buttons d-flex w-100'>
            <button className='btn btn-danger me-2' onClick={() => { if(window.confirm('Are you sure you want to generate a pdf with these parameters?')) { this.onClick() } }}>Generate Summary</button>
            <button className='btn btn-primary' onClick={this.onPreviewClick}>Preview Summary</button>
        </div>
      </div>
    )
  }
}

export default SummaryGenerator

