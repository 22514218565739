import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js'
import zip from 'zippo'

const ELEMENT_OPTIONS = {
  classes: {
    invalid: 'is-invalid'
  },
  style: {
    base: {
      fontSize: '20px',
      fontFamily: 'Montserrat',
      lineHeight: '30px',
      '::placeholder': {
        color: '#ACBACC',
      },
      '::focus': {
        color: '#242C36',
        backgroundColor: '#fff',
        borderColor: '#9f9fce',
        outline: 0,
        boxShadow: 'inset 0 1px 2px rgba(23, 23, 26, 0.075), 0 0 0 0.25rem rgba(62, 62, 157, 0.25)'
      }
    },
    invalid: {
      color: '#9e2146',
    }
  },
}

class PaymentCard extends React.Component {
  state = {
    loaded: false,
    nameOnCard: this.props.user.name,
    zipCode: '',
    hadZipCode: false,
    errors: {},
    inputs: { nameOnCard: true },
    disabled: true
  }

  // Returns true if a string is blank, null or undefined
  isBlank = (string) => { return (!string || /^\s*$/.test(string)) }

  // Returns true if the given code is a key in state.errors
  hasError = (code) => { return (code in this.state.errors) }

  // Determines if payment button should be enabled and updates local and parent component state
  checkSubmittable = async () => {
    let { inputs } = this.state
    if(inputs.nameOnCard && inputs.zipCode && inputs.cardCvc && inputs.cardExpiry && inputs.cardNumber){
      this.props.updateParentState({ disabled: false })
      this.setState({ disabled: false })
    } else {
      this.props.updateParentState({ disabled: true })
      this.setState({ disabled: true })
    }
  }

  // Payment form submission using a Credit Card
  handleSubmit = async (event) => {
    // Don't allow sneaky ways of submitting the form
    event.preventDefault()
    if (this.state.disabled) { return }

    // Disable until Stripe.js is loaded
    const { stripe, elements } = this.props
    if (!stripe || !elements) { return }

    // Create payment method w/ card
    let payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: this.state.nameOnCard,
        email: this.props.user.email,
        phone: this.props.user.phone,
        address: {
          postal_code: this.state.zipCode,
        }
      }
    })

    // Hoist result with onPaymentMethod
    if (payload.error) {
      this.props.onPaymentMethod(null)
      Spinner.error(`Error! ${payload.error.message}`)
      console.error(error);
    } else if(payload.paymentMethod) {
      this.props.onPaymentMethod(payload.paymentMethod)
    } else {
      console.error("nothing happened ¯\_(ツ)_/¯");
    }
  }

  ///////////////////////////////////////////////
  // Event Handlers
  ///////////////////////////////////////////////

  // Presence validation for nameOnCard
  validateName = async (target) => {
    let { errors, inputs } = this.state
    let valid = !this.isBlank(target.value)

    if(valid){
      delete errors.nameOnCard
      inputs.nameOnCard = true
    } else {
      errors.nameOnCard = { code: 'nameOnCard', message: "Name can't be blank." }
      inputs.nameOnCard = false
    }

    this.setState({ errors: errors, inputs: inputs })
    this.checkSubmittable()
    return(valid)
  }

  // Zip code validation using zippo
  // https://github.com/bendrucker/zippo
  validateZipCode = async (target) => {
    let { errors, inputs } = this.state
    let valid = zip.validate(target.value)

    if(valid){
      delete errors.zipCode
      inputs.zipCode = true
    } else if(this.state.hadZipCode) {
      // only display validation errors after zip code becomes valid for
      // the first time, then re-validate upon every keystroke afterward
      errors.zipCode = { code: 'zipCode', message: 'Zip code is invalid.' }
      inputs.zipCode = false
    }

    this.setState({ errors: errors, inputs: inputs })
    this.checkSubmittable()
    return(valid)
  }

  // Validate Stripe fields as the customer types
  handleStripeInputChange = async (event) => {
    let { errors, inputs } = this.state;

    // stripe-js elements
    if (event.error) {
      errors[event.elementType] = event.error
      inputs[event.elementType] = false
    } else {
      delete errors[event.elementType]
      inputs[event.elementType] = true
    }

    this.setState({ errors: errors, inputs: inputs })
    this.checkSubmittable()
  }

  ///////////////////////////////////////////////
  // DOM
  ///////////////////////////////////////////////

  renderError = (error) => {
    return(<li key={error.code}>{error.message}</li>)
  }

  renderErrors = (event) => {
    if (Object.keys(this.state.errors).length > 0) {
      return(
        <React.Fragment>
          <Alert variant="danger">
            <ol className="mb-0">
              {Object.values(this.state.errors).map((error) => this.renderError(error))}
            </ol>
          </Alert>
        </React.Fragment>
      )
    }
  }

  ///////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////

  render () {
    return(
      <div>
        {this.renderErrors()}
        <Form noValidate id='checkout-form' onSubmit={this.handleSubmit}>
          <Row className="mb-4">
            <Form.Group>
              <Form.Label>Name on Card</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="nameOnCard"
                value={this.state.nameOnCard}
                placeholder="John Smith"
                onChange={(event) => {
                  this.setState({
                    nameOnCard: event.target.value
                  }, () => {
                    this.validateName(event.target)
                  })
                }}
                isInvalid={this.hasError('nameOnCard')}
                required
              />
            </Form.Group>
          </Row>
          <Row className="mb-4">
            <Form.Group>
              <Form.Label>Card Number</Form.Label>
              <Form.Control
                as={CardNumberElement}
                id="cardNumber"
                type="text"
                size="lg"
                onChange={this.handleStripeInputChange}
                options={ELEMENT_OPTIONS}
              />
            </Form.Group>
          </Row>
          <Row className="mb-4">
            <Form.Group as={Col}>
              <Form.Label>Expiration Date</Form.Label>
              <Form.Control
                as={CardExpiryElement}
                id="expiry"
                size="lg"
                onChange={this.handleStripeInputChange}
                options={ELEMENT_OPTIONS}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="payment">CVC</Form.Label>
              <Form.Control
                as={CardCvcElement}
                id="cvc"
                size="lg"
                onChange={this.handleStripeInputChange}
                options={ELEMENT_OPTIONS}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="payment">Zip Code</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="zipCode"
                value={this.state.zipCode}
                placeholder="94025"
                onChange={(event) => {
                  this.setState({
                    // zippo removes non-digit chars and limits the string to 5 characters
                    zipCode: zip.parse(event.target.value)
                  }, () => {
                    // only display validation errors after zip code becomes valid for
                    // the first time, then re-validate upon every keystroke afterward
                    zip.validate(event.target.value) && this.setState({ hadZipCode: true })
                    this.validateZipCode(event.target)
                  })
                }}
                isInvalid={this.hasError('zipCode')}
                required
              />
            </Form.Group>
          </Row>
        </Form>
      </div>
    )
  }
}

export default PaymentCard
