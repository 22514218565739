import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'

class CouponCode extends React.Component {
  state = {
    show: false,
    code: '',
    error: ''
  }

  handleShow = () => { this.setState({ show: true }) }
  handleHide = () => { this.setState({ show: false }) }
  handleChange = (event) => { this.setState({ code: event.target.value }) }

  submitCode = (event) => {
    event.preventDefault()

    $.post('/verify-coupon-code', {
      code: this.state.code
    }, () => {
      Spinner.show('validating code...')
      this.setState({ show: false })
    }).fail(() => {
      this.setState({ error: 'Invalid code.' })
    })
  }

  ///////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////

  render () {
    return (
      <React.Fragment>
        <Button
          variant="link"
          size="sm"
          className="pe-0 mt-3"
          style={{ textDecoration: 'none' }}
          onClick={this.handleShow}
        >
          I have coupon code
        </Button>

        <Modal show={this.state.show} onHide={this.handleHide} centered>
          <Modal.Header>
            <Modal.Title>Enter Coupon Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
           <Form.Control
            size="lg"
            type="text"
            value={this.state.code}
            onChange={this.handleChange}
            isInvalid={this.state.error != ''}
            required
          />
          </Modal.Body>
          <Modal.Footer>
            <div className="flex-fill text-danger">{this.state.error}</div>
            <Button variant="secondary" onClick={this.handleHide}>
              Close
            </Button>
            <Button variant="primary" onClick={this.submitCode}>
              Use Code
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )
  }
}

export default CouponCode
