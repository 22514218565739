import axios from 'axios';
import React from 'react'

class VideoUpload extends React.Component {
  state = {
    signedUrl: this.props.url,
    uploadUrl: this.props.upload_url,
    selectedFile: null,
    s3Key: this.props.s3_key,
    fileText: this.props.file_text,
    buttonText: this.props.button_text,
    shouldRedirect: false,
    succeeded: false
  }

  sizeInMB = (file) => {
    return (file.size / (1024*1024)).toFixed(2);
  }

  beginSpinnerCycle = (size, name) => {
    // First rounds
    let firstRound = () => {
      if (this.state.succeeded) return;
      Spinner.show(`Uploading ${name}...`)
    }
    let secondRound = () => {
      if (this.state.succeeded) return;
      Spinner.update('Video files can be quite large and take up to 10 minutes to upload...');
    }
    let thirdRound = () => {
      if (this.state.succeeded) return;
      Spinner.update(`Your file is ${size} megabytes in size. Check the upload progress below...`);
    }
    let fourthRound = () => {
      if (this.state.succeeded) return;
      Spinner.update('We appreciate you taking the time to record and upload your video...');
    }

    // If things take a really long time...
    let extraRound1 = () => {
      Spinner.update('Thank you for your patience! Video uploading...');
    }
    let extraRound2 = () => {
      Spinner.update('All systems normal! Video uploading...');
    }
    let extraRound3 = () => {
      Spinner.update('Please do not refresh the page! Video uploading...');
    }

    let roundCounter = 0;
    let extraRounds = [extraRound1, extraRound2, extraRound3]
    let runExtraRound = () => {
      if (this.state.succeeded) return;

      let idx = roundCounter % extraRounds.length;
      let randomRound = extraRounds[idx]
      roundCounter = roundCounter + 1;
      randomRound();
    }

    let runExtraRounds = () => {
      runExtraRound();
      setInterval(runExtraRound, 8000)
    }

    firstRound();
    setTimeout(secondRound, 8000);
    setTimeout(thirdRound, 16000);
    setTimeout(fourthRound, 24000);
    setTimeout(runExtraRounds, 32000);
  }

  ///////////////////////////////////////////////
  // Event Handlers
  ///////////////////////////////////////////////

  onFileChange = (e) => {
    this.setState({ selectedFile: e.target.files[0] })
  }

  onClick = () => {
    this.uploadVideo();
    this.setState({ selectedFile: null });
  }

  ///////////////////////////////////////////////
  // Axios
  ///////////////////////////////////////////////

  uploadVideo = () => {
    let { selectedFile, uploadUrl, s3Key, signedUrl } = this.state
    let size = this.sizeInMB(selectedFile);
    let options = {
      headers: {
        'Content-Type': selectedFile.type
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        Spinner.updatePercentage(`Progress: ${percentCompleted}%`);
      }
    };

    this.beginSpinnerCycle(size, selectedFile.name);

    let uploadParams = () => {
      return ({
        filename: selectedFile.name,
        content_type: selectedFile.type,
        file_type: 'video',
        size: selectedFile.size,
        storage_type: 's3',
        path: s3Key
      })
    }

    let allowRedirect = () => {
      this.setState({ succeeded: true })
      Spinner.update('Upload successful!');
      this.setState({ shouldRedirect: true });
    }

    axios.put(signedUrl, selectedFile, options).then(function (result) {
      console.log('File successfully uploaded to S3');

      return axios.post(uploadUrl, uploadParams());
    }).then(function (result) {
      console.log('Successfully saved metadata');
      // Set state to ensure redirect
      allowRedirect();
    }).catch(function (err) {
      console.log(err);
    });
  }
  
  render() {
    if (this.state.shouldRedirect) {
      // redirect on upload success
      // Implement this when you can figure out how to install `react-router` with yarn
      // return <Redirect to = {{ pathname: this.state.uploadUrl }} />;
      return <meta httpEquiv="refresh" content={`1.5; URL=${this.state.uploadUrl}`} />
    } else {
      return (
        <div className='file-upload mt-4 d-flex justify-content-between'>
        <div className='upload-input mx-3 mb-3'>
          <label id="file-input">{this.state.fileText}:&nbsp;&nbsp;</label>        
          <input type="file" onChange={this.onFileChange} id='file-input'></input>
        </div>

        <button className="btn btn-primary mx-3" type='submit' onClick={this.onClick} disabled={!this.state.selectedFile}>{this.state.buttonText}</button>
      </div> 
    )}
  }
}

export default VideoUpload

