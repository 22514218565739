import React from 'react'
import moment from 'moment-timezone'
import Countdown, { zeroPad } from 'react-countdown'

class ReservationCountdown extends React.Component {

  // Renderer callback with condition
  renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      alert('You ran outta time!')
      window.location = '/schedule'
    } else {
      return (
        <span style={{ display: 'inline-block', width: '84px', marginLeft: '1px', textAlign: 'left' }}>
          {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  }

  scheduledAt = () => {
    return(moment.tz(this.props.createdAt*1000, moment.tz.guess()).add(1, 'hour'));
  }

  ///////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////

  render () {
    let info = (this.props.is_consultation ? null : <small className="text-muted">After your evaluation, you will receive an invoice to submit for possible reimbursement with your insurance or FSA.</small>);
    return (
      <div className="text-center" style={{ margin: '1rem 0 4rem' }}>
        <h1 className="display-6">Your appointment is reserved until {this.scheduledAt().format('h:mm A')}</h1>
        <p className="lead">
          This reservation will be released in <Countdown date={this.scheduledAt().toDate()} renderer={this.renderer} /> without payment.
        </p>
        {info}
      </div>
    );
  }
}

export default ReservationCountdown
