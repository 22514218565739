import React from 'react'
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js'

class PaymentRequest extends React.Component {
  state = {
    canMakePayment: false,
    checkedAvailability: false
  }

  async createPaymentRequest(stripe) {
    // Initialize PaymentRequest (Apple / Microsoft / Google Pay)
    this.paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Jigsaw Diagnostics',
        amount: this.props.price,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true
    })

    // Listen for PaymentMethod changes and update parent component
    this.paymentRequest.on('paymentmethod', async (event) => {
      this.props.onPaymentMethod(event.paymentMethod)
      event.complete('success')
    })

    // Check availability of the PaymentRequest API
    if (await this.paymentRequest.canMakePayment()) {
      this.setState({ canMakePayment: true, checkedAvailability: true })
    } else {
      this.setState({ canMakePayment: false, checkedAvailability: true })
    }
  }

  ///////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////

  async componentDidUpdate() {
    const {stripe} = this.props

    // Create PaymentRequest after Stripe.js loads
    if (stripe && !this.paymentRequest) {
      this.createPaymentRequest(stripe)
    }
  }

  render () {
    return(
      <React.Fragment>
        {this.state.canMakePayment && (
          <React.Fragment>
            <PaymentRequestButtonElement
              options={{
                style: {
                  paymentRequestButton: {
                    type: 'default',
                    theme: 'dark',
                    height: '48px'
                  }
                },
                paymentRequest: this.paymentRequest
              }}
            />
            <div className="separator">
              <small>or pay with card</small>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default PaymentRequest
