import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Slider from 'react-slick'
import RadioButtons from './questionnaire/RadioButtons'
import Email from './questionnaire/Email'
import Text from './questionnaire/Text'
import TextArea from './questionnaire/TextArea'
import Birthdate from './questionnaire/Birthdate'
import Select from './questionnaire/Select'
import WeightInput from './questionnaire/WeightInput'
import Checkboxes from './questionnaire/Checkboxes'
import axios from 'axios'
import parse from 'html-react-parser'
import IntroImage from "../../assets/images/screener_intro.png";

class Questionnaire extends React.Component {
  state = {
    questions: this.props.questions || [],
    currentQuestion: this.props.current_question
  }

  numQuestionsAnswered = () => {
    return(this.state.questions.map(question => (question.value)).filter(e => e != null).length)
  }

  goNext = () => {
    // Ensures questions are not accidentally skipped over in the Questionnaire
    if (this.numQuestionsAnswered() < this.state.currentQuestion) return;

    this.setState({ currentQuestion: this.state.currentQuestion + 1 }, () => {
      this.slider.slickGoTo(this.state.currentQuestion)
      $('html, body').animate({ scrollTop: 0 });
    })
  }

  goBack = () => {
    this.setState({ currentQuestion: this.state.currentQuestion - 1 }, () => {
      this.slider.slickGoTo(this.state.currentQuestion)
    })
  }

  progress = () => {
    return(((this.state.currentQuestion - 1) * 100) / this.state.questions.length)
  }

  isLastQuestion = () => {
    return(this.state.currentQuestion == this.state.questions.length)
  }

  ///////////////////////////////////////////////
  // Event Handlers
  ///////////////////////////////////////////////

  submitAnswer = (questionId, answer) => {
    let callback_path = this.props.callback_path

    // Keep track of user's selected answers
    this.state.questions.find(question => question.id == questionId)["value"] = answer
    let answers = this.state.questions.map(question => ({ [question.id]: question.value }))

    // Persist answers to the database
    axios.patch(this.props.update_path, { questionnaire: { answers: answers } }).then((response) => {
      if(response.data.status == "finished" && this.isLastQuestion()){
        $('form').animate({ opacity: 0, top: '500px' }, 250, 'swing', () => {
          window.location = callback_path
        })
      }

      // Update slide
      if(this.state.currentQuestion < this.state.questions.length) {
        this.goNext()
      }
    })

  }

  ///////////////////////////////////////////////
  // DOM
  ///////////////////////////////////////////////

  renderInput = (question) => {
    console.log(question.type)
    switch(question.type) {
      case 'text': return <Text question={question} onSubmit={this.submitAnswer} next_button={this.props.next_button} />
      case 'radio': return <RadioButtons question={question} onSubmit={this.submitAnswer} />
      case 'email': return <Email question={question} onSubmit={this.submitAnswer} next_button={this.props.next_button} />
      case 'textarea': return <TextArea question={question} onSubmit={this.submitAnswer} next_button={this.props.next_button} />
      case 'birthdate': return <Birthdate question={question} onSubmit={this.submitAnswer} next_button={this.props.next_button} />
      case 'checkboxes': return <Checkboxes question={question} onSubmit={this.submitAnswer} next_button={this.props.next_button} />
      case 'select': return <Select question={question} onSubmit={this.submitAnswer} next_button={this.props.next_button} locale={this.props.locale} />
      case 'weight': return <WeightInput question={question} onSubmit={this.submitAnswer} next_button={this.props.next_button} pounds_label={this.props.pounds_label} ounces_label={this.props.ounces_label} />
      default: console.error("unknown question type: ", question)
    }
  }

  renderBackButton = (index) => {
    if(index >= 0) {
      return(<Button variant="light" size="lg" className="w-100" onClick={this.goBack}>{ this.props.back_button || "Back" }</Button>)
    }
  }

  renderQuestion = (question, index) => {
    return(
      <div key={question.id}>
        <div className="container pt-3">
          <header className="mb-3">
            <h4 className="mb-0">{ index + 1 }. { question.prompt }</h4>
            <small className="text-muted">{ question.explanation }</small>
          </header>
          {this.renderInput(question)}
          {this.renderBackButton(index)}
        </div>
      </div>
    )
  }

  renderIntro = (intro) => {
    if(intro){
      return(
        <div key='introduction'>
          <div id="questionnaire-intro-body" className="container">
            <header className="mb-5 text-center">
              <h2>{intro.title}</h2>
              <h4 className="fw-normal text-muted mb-0">{parse(intro.subtitle)}</h4>
            </header>
            <img src={IntroImage} alt="Parent and child on couch"/>
            <small className="text-muted">{parse(intro.text)}</small>
            <Button variant="primary" size="lg" onClick={this.goNext}>{ this.props.begin_button || "Begin" }</Button>
          </div>
        </div>
      )
    }
  }

  renderTitle = () => {
    if(this.state.currentQuestion == 0) {
      return(<h4>{this.props.intro.nav}</h4>)
    } else {
      return(<h4>{ this.props.question_title || "Question" } {this.state.currentQuestion}/{this.state.questions.length}</h4>)
    }
  }

  ///////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////

  componentDidMount() {
    this.slider.slickGoTo(this.state.currentQuestion)
  }

  render() {
    var slickOptions = {
      accessibility: false,
      dots: false,
      arrows: false,
      infinite: false,
      draggable: false,
      swipe: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      easing: 'swing',
      cssEase: 'ease-in-out'
    }

    return (
      <div className={`slide-${this.state.currentQuestion}`}>
        <Navbar id='questionnaire-title' bg='light'>
          <Container fluid className='justify-content-center'>
            {this.renderTitle()}
          </Container>
        </Navbar>

        <ProgressBar id='navbar-progress' now={this.progress()} />
        <Form style={{ position: 'relative' }}>
          <Slider ref={slider => (this.slider = slider)} {...slickOptions}>
            {this.renderIntro(this.props.intro)}
            {this.state.questions.map((question, index) => this.renderQuestion(question, index))}
          </Slider>
        </Form>
      </div>
    )
  }
}

Questionnaire.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default Questionnaire

