import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import CheckoutForm from './payment/CheckoutForm'

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY)

class Payment extends React.Component {

  ///////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////

  render() {
    return (
      <React.Fragment>
        <Elements stripe={stripePromise} options={{
          fonts: [{
            cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600'
          }]
        }}>
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <CheckoutForm stripe={stripe} elements={elements} {...this.props} />
            )}
          </ElementsConsumer>
        </Elements>
      </React.Fragment>
    )
  }
}

export default Payment
