import React from 'react'
import moment from 'moment-timezone'

class LocalTime extends React.Component {
  render() {
    return (
      <React.Fragment>
        {moment.tz(this.props.time*1000, moment.tz.guess()).format(this.props.format || 'dddd, MMMM Do [at] h:mma z')}
      </React.Fragment>
    )
  }
}

export default LocalTime
