import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import ControlLabel from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

class WeightInput extends React.Component {
  state = {
    value: (this.props.question.value || ''),
    pounds: 0,
    ounces: 0
  }

  ///////////////////////////////////////////////
  // Event Handlers
  ///////////////////////////////////////////////

  handlePoundsChange = (event) => {
    let pounds = parseInt(event.target.value)
    this.setState({ pounds: pounds })
  }

  handleOuncesChange = (event) => {
    let ounces = parseInt(event.target.value)
    this.setState({ ounces: ounces })
  }

  handleSubmit = (event) => {
    this.props.onSubmit(this.props.question.id, this.state.value)
  }

  ///////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////

  componentDidUpdate(prevProps, prevState) {
    if (this.state.ounces >= 16) { this.setState({ ounces: 0, pounds: (parseInt(this.state.pounds) + 1) }) }

    if ((prevState.pounds !== this.state.pounds || prevState.ounces !== this.state.ounces) && !isNaN(this.state.pounds) && !isNaN(this.state.ounces)) {
      let weightString = `${this.state.pounds} lbs. ${this.state.ounces} oz.`
      this.setState({ value: weightString })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className='d-flex'>
          <div className='w-100'>
            <ControlLabel controlId={`${this.props.question.answer.id}-pounds`}>{this.props.pounds_label || "Pounds"}</ControlLabel>
            <Form.Control
              type="number"
              tabIndex="-1"
              name={this.props.question.id}
              id={`${this.props.question.answer.id}-pounds`}
              placeholder="pounds"
              className="mb-4 form-control me-2"
              min={0}
              max={20}
              step={1}
              value={this.state.pounds}
              onChange={this.handlePoundsChange}
              size="lg"
              />
          </div>
          <div className='w-100'>
            <ControlLabel controlId={`${this.props.question.answer.id}-ounces`} className='ms-2'>{this.props.ounces_label || "Ounces"}</ControlLabel>
            <Form.Control
              type="number"
              tabIndex="-1"
              name={this.props.question.id}
              id={`${this.props.question.answer.id}-ounces`}
              placeholder="ounces"
              className="mb-4 form-control ms-2"
              min={0}
              max={16}
              step={1}
              value={this.state.ounces}
              onChange={this.handleOuncesChange}
              size="lg"
            />
          </div>
        </div>
        <Button
          variant="primary"
          size="lg"
          className="w-100 mb-3"
          onClick={this.handleSubmit}
          disabled={this.state.value.length == 0 || isNaN(this.state.pounds) || isNaN(this.state.ounces)}
        >
          {this.props.question.cta || this.props.next_button || 'Next'}
        </Button>
      </React.Fragment>
    );
  }
}

Text.propTypes = {
  question: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default WeightInput
