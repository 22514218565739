import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

class Email extends React.Component {
  state = {
    value: this.props.question.value || '',
    invalid: false
  }

  isValidEmail = (email) => {
    if (email.match(/.+@.+/g)) {
      this.setState({ invalid: false })
      return true
    } else {
      this.setState({ invalid: true })
      return false
    }
  }

  ///////////////////////////////////////////////
  // Event Handlers
  ///////////////////////////////////////////////

  handleChange = (event) => {
    this.setState({ value: event.target.value })
    if(this.state.invalid){ this.isValidEmail(event.target.value) }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    if(this.isValidEmail(this.state.value)) {
      this.props.onSubmit(this.props.question.id, this.state.value)
    }
  }

  ///////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////

  render() {
    return (
      <React.Fragment>
        <Form.Control
          type="email"
          tabIndex="-1"
          name={this.props.question.id}
          id={this.props.question.answer.id}
          placeholder={this.props.question.answer.placeholder}
          size="lg"
          className="mb-4"
          value={this.state.value}
          isInvalid={this.state.invalid}
          onChange={this.handleChange}
          onKeyPress={(event) => { if(event.key == "Enter"){ event.preventDefault() } }}
        />
        <Button
          variant="primary"
          size="lg"
          className="w-100 mb-3"
          onClick={this.handleSubmit}
          disabled={this.state.value.length == 0}
        >
          {this.props.question.cta || this.props.next_button || 'Next'}
        </Button>
      </React.Fragment>
    );
  }
}

Email.propTypes = {
  question: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default Email
