import React from 'react'
import moment from 'moment-timezone'

class LocalizedTime extends React.Component {

  render() {
    return (
      <React.Fragment>
        <p className='lead'>
          {this.props.before}
          {moment.tz(this.props.time*1000, moment.tz.guess()).format('dddd, MMMM Do [at] h:mma z')}
          {this.props.after}
        </p>
      </React.Fragment>
    )
  }
}

export default LocalizedTime
