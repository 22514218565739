import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import moment from 'moment-timezone'
import PaymentCard from './PaymentCard'
import PaymentRequest from './PaymentRequest'
import CouponCode from './CouponCode'
import KlarnaImage from "../../../assets/images/klarna.svg";

class CheckoutForm extends React.Component {
  state = {
    disabled: true,
    clientSecret: '',
    user: {},
    patientName: '',
    startTime: '',
    package: '',
    paymentMethod: null,
    flash: null
  }

  ///////////////////////////////////////////////
  // State Hoisting
  ///////////////////////////////////////////////

  updatePaymentMethod = (paymentMethod) => {
    this.setState({ paymentMethod: paymentMethod }, () => {
      if(paymentMethod){ this.setState({ disabled: false }) }
      this.confirmPaymentIntent(paymentMethod)
    })
  }

  updateState = (object) => { this.setState(object) }

  ///////////////////////////////////////////////
  // Process Payment
  ///////////////////////////////////////////////

  async confirmPaymentIntent(paymentMethod) {
    // turn on spinner w/ message
    Spinner.show('confirming payment...')

    // confirm payment using paymentMethod (apple pay, credit card, etc..)
    const { paymentIntent, error } = await this.props.stripe.confirmCardPayment(
      this.state.clientSecret, { payment_method: paymentMethod.id }
    )

    if (error) {
      console.error(error);
      Spinner.error(`Payment Failed! ${error.name}`)
    } else if(paymentIntent) {
      console.log('paymentIntent: ', paymentIntent);
      Spinner.update(`confirmed!`);
      $.post('/confirm-payment-intent', { payment: paymentIntent })
    } else {
      console.error("nothing happened ¯\_(ツ)_/¯");
    }
  }

  async confirmKlarnaPaymentIntent(e) {
    e.preventDefault();

    // turn on spinner w/ message
    Spinner.show('redirecting to Klarna...')

    // Redirects away from the client
    const { klarnaPaymentIntent, error } = await this.props.stripe.confirmKlarnaPayment(
      this.state.clientSecret,
      {
        payment_method: {
          billing_details: {
            email: this.props.user.email,
            address: {
              country: 'US',
            },
          },
        },
        return_url: this.props.return_url,
      }
    );

    if (error) {
      console.error(error);
      Spinner.error(`Failed to redirect to Klarna! ${error.name}`)
    } else if(paymentIntent) {
      console.log('paymentIntent: ', klarnaPaymentIntent);
      Spinner.update(`redirecting to Klarna...`);
      $.post('/confirm-payment-intent', { payment: paymentIntent })
    } else {
      console.error("nothing happened ¯\_(ツ)_/¯");
    }
  }

  ///////////////////////////////////////////////
  // Event Handlers
  ///////////////////////////////////////////////

  handleKeyDown(e) {
    if (e.keyCode == 75 && e.shiftKey && e.altKey) {
      let emailButton = document.querySelector('#klarna-email-button');

      if (!emailButton.classList.contains('d-none')) {
        $('#klarna-email-button').addClass('d-none');
      } else {
        $('#klarna-email-button').removeClass('d-none');
      }
    }
  }

  sendPaymentEmail(e) {
    e.preventDefault();

    axios.post('/send_payment_email').then(response => {
      this.setState({ flash: `Email sent successfully to ${this.props.user.email}`});
    }).catch(error => {
      console.log(error) ;
    });
  }

  ///////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////

  componentDidMount() {
    // Find or create a PaymentIntent to retreive clientSecret
    axios.get('/create-payment-intent').then(response => {
      this.setState({ clientSecret: response.data.clientSecret })
    })

    document.addEventListener("keydown", this.handleKeyDown);
  }

  render () {
    let withDeferredPayment = this.props.with_deferred_payment
    let couponCode = (this.props.is_consultation || withDeferredPayment ? null : <CouponCode />);
    let forPatient = (this.props.patient_name ? `for ${this.props.patient_name}` : null)
    let flash = (this.state.flash ? <div className='alert alert-success text-center mt-3'>{this.state.flash}</div> : null);

    return(
      <React.Fragment>
        <Row>
          <Col>
            {withDeferredPayment ?
              <Container className='callout'
                style={{ padding: '3rem 2.5rem 1.5rem', marginBottom: '2rem' }}>
                <Col><p>Jigsaw Diagnostics uses Klarna for our deferred payment plans to ensure convenience and security. All financing options are determined by Klarna.</p></Col>
                <Col><p>To continue with your deferred payment plan, please click the button below.</p></Col>
                <Row>
                  <Col><hr /></Col>
                </Row>
                <Row className='align-items-center justify-content-center'>
                  <button onClick={this.confirmKlarnaPaymentIntent.bind(this)} className='w-75 btn btn-primary btn-lg font-bold'>
                    <img src={KlarnaImage} alt="Klarna logo on button" style={{height: "20px"}} />
                  </button>
                </Row>
                <Row className='align-items-center justify-content-center'>
                  <button className='w-75 btn btn-danger btn-lg font-bold mt-3 d-none' id='klarna-email-button' onClick={this.sendPaymentEmail.bind(this)}>
                    Send Payment Email
                  </button>
                  {flash}
                </Row>
              </Container> : <div>
                <PaymentRequest
                  stripe={this.props.stripe}
                  onPaymentMethod={this.updatePaymentMethod}
                  price={this.props.price}
                />
                <PaymentCard
                  stripe={this.props.stripe}
                  elements={this.props.elements}
                  onPaymentMethod={this.updatePaymentMethod}
                  updateParentState={this.updateState}
                  user={this.props.user}
                />
              </div>
            }
          </Col>
          <Col>
            <Container className='callout' style={{ padding: '3rem 2.5rem 1.5rem', marginBottom: '2rem' }}>
              <Row>
                <Col><strong>Your Order</strong></Col>
              </Row>
              <Row>
                <Col><hr /></Col>
              </Row>
              <Row>
                <Col className="col-8">
                  <small>{this.props.package} {forPatient} on {moment.tz(this.props.start_time*1000, moment.tz.guess()).format('dddd, MMMM Do [at] h:mma z')}</small>
                </Col>
                <Col className="text-end">
                  <small><strong>${this.props.price/100}</strong></small>
                </Col>
              </Row>
              <Row>
                <Col><hr /></Col>
              </Row>
              <Row>
                <Col><strong>Total</strong></Col>
                <Col className="text-end"><strong>${this.props.price/100}</strong></Col>
              </Row>
              <Row>
                <Col className="text-end">
                  {couponCode}
                </Col>
              </Row>
            </Container>
            {withDeferredPayment || <div className="d-grid gap-2">
              <Button form='checkout-form' type="submit" variant="primary" size="lg" disabled={this.state.disabled}>
                Submit Payment
              </Button>
            </div>}
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default CheckoutForm
